<template>
  <div class="center-between mb-4">
    <h1 class="m-0" v-text="$t('sidebarMenu.label', 2)" />
    <button
      class="small"
      @click="openModal({
        name: '',
      }, 'editSidebarMenu')"
    >
      +
    </button>
  </div>

  <entity-table
    v-model:sortBy="sortBy"
    v-model:sortDirection="sortDirection"
    :entities="entities"
    :fields="fields"
    :current-page="currentPage"
    :total-items="totalItems"
    :loading="loadingEntities"
    @click:delete="openModal($event, 'deleteSidebarMenu')"
    @update:sort="loadEntities()"
    @update:page="currentPage = $event; loadEntities()"
  />

  <edit-sidebar-menu @update="loadEntities()" />
  <delete-modal
    type="deleteSidebarMenu"
    entity-translation-key="sidebarMenu"
    label="name"
    @update="loadEntities()"
  />
</template>

<script>
import { useStore } from 'vuex';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditSidebarMenu from '@/components/entities/sidebar_menu/EditSidebarMenu.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  components: { DeleteModal, EditSidebarMenu, EntityTable },
  setup() {
    const store = useStore();
    const { sidebarMenu: fields } = useEntityFields();
    const {
      entities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadingEntities,
      loadEntities,
    } = useLoadEntities('/sidebar_menus', { sort: 'name' });
    const openModal = (entity, modal) => {
      store.dispatch('modals/openModal', { entity, modal });
    };

    return {
      fields,
      entities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadingEntities,
      loadEntities,
      openModal,
    };
  },
};
</script>
