<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editSidebarMenuEntry"
    :options="{
      properties: {
        name: '',
        registerLayout: null,
        sidebarMenu: null,
      },
      postUri: '/sidebar_menu_entries',
      label: $t('sidebarMenuEntry.label')
    }"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" />
    <multiselect
      v-model="properties.registerLayout"
      :required="true"
      :options="async (q) => loadRegisterLayoutOptions(q, properties.registerLayout)"
      :error="errors.registerLayout"
      :filter-results="false"
      :form-label="$t('registerLayout.label')"
      searchable
      :loading="loadingRegisterLayoutOptions"
    />
  </modal-form>
</template>
<script>
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';

export default {
  components: {
    FancyInput,
    ModalForm,
    Multiselect,
  },
  setup() {
    const {
      loadOptions: loadRegisterLayoutOptions,
      loading: loadingRegisterLayoutOptions,
    } = useLoadOptions('/register_layouts', 'name');

    return {
      loadingRegisterLayoutOptions,
      loadRegisterLayoutOptions,
    };
  },
};
</script>
