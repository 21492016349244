<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editSidebarMenu"
    :options="{
      properties: {
        name: '',
      },
      label: $t('sidebarMenu.label'),
      postUri: '/sidebar_menus',
    }"
    @update="$emit('update')"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />
  </modal-form>
</template>
<script>
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';

export default {
  name: 'EditSidebarMenu',
  components: { FancyInput, ModalForm },
  emits: ['update'],
};
</script>
