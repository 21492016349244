<template>
  <div>
    <spinner v-if="loading" color="#000" />
    <template v-else>
      <edit-sidebar-menu @update="loadEntities" />
      <delete-modal
        type="deleteSidebarMenu"
        entity-translation-key="sidebarMenu"
        label="name"
        @update="$router.push('/sidebar_menus')"
      />
      <entity-card
        :fields="[]"
        :entity="entity"
        :title="entity.name"
        big-heading
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <button
              class="small white mr-2"
              @click="$store.dispatch('modals/openModal', { entity, modal: 'deleteSidebarMenu'})"
              v-text="$t('general.action.delete')"
            />
            <button v-t="'general.action.edit'" class="small" @click="$store.dispatch('modals/openModal', { entity, modal: 'editSidebarMenu' })" />
          </div>
        </template>
        <template #additional>
          <button class="small" @click="$store.dispatch('modals/openModal', { modal: 'editSidebarMenuEntry', entity: {sidebarMenu: entity['@id']} })">
            {{ $t('general.action.add') }}
          </button>
          <transition-group name="fade" class="container" tag="div">
            <div v-for="(sidebarMenuEntry, idx) in sortedEntries" :key="sidebarMenuEntry['@id']" class="item sidebar-menu-entry">
              <span>
                <strong>{{ sidebarMenuEntry.name }}:</strong> {{ sidebarMenuEntry.registerLayout.name }}
              </span>
              <div class="actions">
                <font-awesome-icon icon="pen-to-square" class="icon" @click="$store.dispatch('modals/openModal', { modal: 'editSidebarMenuEntry', entity: normalizeEntry(sidebarMenuEntry) })" />
                <font-awesome-icon icon="trash-can" class="icon" @click="$store.dispatch('modals/openModal', { modal: 'deleteSidebarMenuEntry', entity: normalizeEntry(sidebarMenuEntry) })" />
                <font-awesome-icon :class="{disabled: idx >= sortedEntries.length - 1}" icon="arrow-down" class="icon" @click="moveDown(idx)" />
                <font-awesome-icon :class="{disabled: idx === 0}" icon="arrow-up" class="icon" @click="moveUp(idx)" />
              </div>
            </div>
          </transition-group>
          <edit-sidebar-menu-entry-modal @update="loadEntities" />
          <delete-modal
            type="deleteSidebarMenuEntry"
            entity-translation-key="sidebarMenuEntry"
            label="name"
            @update="loadEntities()"
          />
        </template>
      </entity-card>
    </template>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import EntityCard from '@/components/shared/EntityCard.vue';
import EditSidebarMenu from '@/components/entities/sidebar_menu/EditSidebarMenu.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditSidebarMenuEntryModal from '@/components/entities/sidebar_menu/EditSidebarMenuEntryModal.vue';

export default {
  name: 'SidebarMenuDetailPage',
  components: {
    EditSidebarMenuEntryModal,
    DeleteModal,
    EditSidebarMenu,
    EntityCard,
    Spinner,
  },
  setup() {
    const route = useRoute();
    const entity = ref(null);
    const buttons = ref([]);
    const button = ref({});
    const loading = ref(true);
    const editModal = ref(null);
    const deleteModal = ref(null);
    const editButtonModal = ref(null);
    const sortedEntries = ref([]);

    const loadEntities = async () => {
      loading.value = true;
      try {
        const { data: sidebarMenu } = await axios.get(`/sidebar_menus/${route.params.id}`);
        entity.value = sidebarMenu;
        sortedEntries.value = [...sidebarMenu.sidebarMenuEntries].sort((a, b) => a.position - b.position);
        for (let i = 0; i < sortedEntries.value.length; i++) {
          sortedEntries.value[i].position = i;
        }
      } catch (err) {
        console.error(err, err.message, err.response);
      }
      loading.value = false;
    };

    onMounted(async () => {
      await loadEntities();
    });

    return {
      entity,
      buttons,
      button,
      loading,
      editModal,
      deleteModal,
      editButtonModal,
      sortedEntries,
      loadEntities,
    };
  },
  methods: {
    moveDown(idx) {
      this.sortedEntries[idx].position++;
      this.sortedEntries[idx + 1].position--;
      this.sortedEntries = [...this.entity.sidebarMenuEntries].sort((a, b) => a.position - b.position);
      this.save();
    },
    moveUp(idx) {
      this.sortedEntries[idx].position--;
      this.sortedEntries[idx - 1].position++;
      this.sortedEntries = [...this.entity.sidebarMenuEntries].sort((a, b) => a.position - b.position);
      this.save();
    },
    async save() {
      const val = {
        ...this.entity,
        sidebarMenuEntries: this.sortedEntries.map((e) => ({ ...this.normalizeEntry(e) })),
      };
      await axios.put(this.entity['@id'], val, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
      });
    },
    normalizeEntry(entity) {
      return {
        ...entity,
        sidebarMenu: this.entity['@id'],
        registerLayout: entity.registerLayout?.['@id'] ?? null,
      };
    },
  },
};
</script>
<style lang="scss">
.sidebar-menu-entry {
  position: relative;
  border: 1px solid #000;
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .actions {
    display: flex;
    justify-content: space-between;
    width: 100px;
    .icon {
      margin-left: 10px;
      cursor: pointer;
      &.disabled {
        cursor: default;
        color: #CCC;
        pointer-events: none;
      }
    }
  }
}
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}
</style>
